import "react-calendar/dist/Calendar.css";
import "./globals.scss";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import isToday from "dayjs/plugin/isToday";
import duration from "dayjs/plugin/duration";
import timezone from "dayjs/plugin/timezone";
import isBetween from "dayjs/plugin/isBetween";
import isYesterday from "dayjs/plugin/isYesterday";
import quarterOfYear from "dayjs/plugin/quarterOfYear";
import advancedFormat from "dayjs/plugin/advancedFormat";
import customParseFormat from "dayjs/plugin/customParseFormat";
import weekday from "dayjs/plugin/weekday";
import "dayjs/locale/en-gb";
import Highcharts from "highcharts";
import HighStock from "highcharts/highstock";
import HighchartsMore from "highcharts/highcharts-more";
import PatternFill from "highcharts/modules/pattern-fill";
import IndicatorsAll from "highcharts/indicators/indicators-all";
import AnnotationsAdvanced from "highcharts/modules/annotations-advanced";
import PriceIndicator from "highcharts/modules/price-indicator";
import FullScreen from "highcharts/modules/full-screen";
import StockTools from "highcharts/modules/stock-tools";
import Exporting from "highcharts/modules/exporting";
import OfflineExporting from "highcharts/modules/offline-exporting";
import ExportData from "highcharts/modules/export-data";
import SolidGauge from "highcharts/modules/solid-gauge";
import NoDataToDisplay from "highcharts/modules/no-data-to-display";
import Boost from "highcharts/modules/boost";
import { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from "react";
import { Context } from "@/lib/Context";
import RootLayout from "@/layouts/root/RootLayout";
import AuthLayout from "@/layouts/auth/AuthLayout";
import BrandLoading from "@/components/brand-loading/BrandLoading";
import { AppProps } from "next/app";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-dropdown-tree-select/dist/styles.css";
import dynamic from "next/dynamic";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { withApp } from "@/lib/app-hoc";
import { isUndefined } from "lodash";
import { useDebounce, useIdle, useUpdateEffect } from "react-use";
import { useRouter } from "next/router";
import Script from "next/script";
import SubscriptionPlans from "@/components/subscription-plans/SubscriptionPlans";
import {
    backgroundColorState,
    customViewsCurrentViewDataState,
    customViewsState,
    customViewsUnsavedState,
    darkerBackgroundColorState,
    darkSurfaceDefaultState,
    greyLighterState,
    greyMediumState,
    negativeState,
    neutralState,
    newbackgroundColorState,
    notificationsListState,
    openMenuState,
    ortexColorState,
    positiveState,
    stockTradingItemIdState,
    subscriptionOfferingsState,
    subscriptionState,
    textColorState,
    themeState,
    universesState,
    universeState,
    userState,
    whiteState,
    userDataState,
    showAppSubscriberModalState,
    showAgreementsModalState,
} from "@/lib/store";
import { Capacitor } from "@capacitor/core";
import { PushNotifications } from "@capacitor/push-notifications";
import { App as CapacitorApp } from "@capacitor/app";
import { createTheme, ThemeOptions, ThemeProvider } from "@mui/material/styles";
import { CssBaseline, StyledEngineProvider } from "@mui/material";
import Payment from "@/components/payment/Payment";
import Offcanvas from "@/components/offcanvas/Offcanvas";
import SignUp from "@/components/sign-up/SignUp";
import { Settings, User } from "@/models/auth";
import CustomViewsUnsavedChangesModal from "@/components/custom-views-unsaved-changes-modal/CustomViewsUnsavedChangesModal";
import { Universe } from "@/models/universe";
import { detectBot, detectDeviceType, isOrtexApp } from "@/lib/utils";
import { LicenseInfo } from "@mui/x-license-pro";
import Color from "@/lib/Color";
import { GoogleAuth } from "@codetrix-studio/capacitor-google-auth";
import { Purchases } from "@revenuecat/purchases-capacitor";
import { getNotifications } from "@/controllers/notifications";
import AppSubscriberModal from "@/components/app-subscriber-modal";
import { signOutFromBackend } from "@/components/cognito-login/CognitoLogin";
import awsExports from "@/lib/aws-config";
import { Amplify } from "aws-amplify";
import { Hub } from "aws-amplify/utils";
import { fetchAuthSession, signOut } from "aws-amplify/auth";
import useHandleAppUpgrade from "src/hooks/useHandleAppUpgrade";
import useGetUserData from "src/hooks/useGetUserData";
import useOpenSubscriptionForm from "src/hooks/useOpenSubscriptionForm";
import AgreementsModal from "@/components/agreements-modal/AgreementsModal";
import { Authenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";

dayjs.extend(utc);
dayjs.extend(isToday);
dayjs.extend(isBetween);
dayjs.extend(isYesterday);
dayjs.extend(quarterOfYear);
dayjs.extend(advancedFormat);
dayjs.extend(customParseFormat);
dayjs.extend(duration);
dayjs.extend(timezone);
dayjs.extend(weekday);

dayjs.locale("en-gb");

LicenseInfo.setLicenseKey("4d957006e9338fdc48d836b30148e4ecTz04OTY4NixFPTE3NDYzNjA3NzgwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y");

const ReactTooltip = dynamic(() => import("react-tooltip"), { ssr: false });

interface MediaQueriesTypes {
    "@media screen and (min-width: 768px)"?: { fontSize: number };
    "@media screen and (min-width: 1280px)"?: { fontSize: number };
    "@media screen and (min-width: 1440px)"?: { fontSize: number };
    "@media screen and (min-width: 1920px)"?: { fontSize: number };
    "@media screen and (min-width: 3840px)"?: { fontSize: number };
}
declare module "@mui/material/styles" {
    interface TypographyVariants {
        h5userMenu: React.CSSProperties;
        captionSessionChip: React.CSSProperties;
        moduleContentTitle: React.CSSProperties;
        newsCardDate: React.CSSProperties;
        minorSubtitle: React.CSSProperties;
        tabs: React.CSSProperties;
    }

    interface TypographyVariantsOptions extends React.CSSProperties {
        h5userMenu?: {
            fontWeight?: number;
            fontSize?: number;
        } & MediaQueriesTypes;
        captionSessionChip?: {
            fontSize: number;
        } & MediaQueriesTypes;
        moduleContentTitle?: {
            fontWeight?: number;
            fontSize: number;
        } & MediaQueriesTypes;
        newsCardDate?: {
            fontWeight?: number;
            fontSize: number;
        } & MediaQueriesTypes;
        minorSubtitle?: {
            fontSize: number;
        } & MediaQueriesTypes;
        tabs?: {
            fontWeight?: number;
            fontSize: number;
        } & MediaQueriesTypes;
        body3?: {
            fontWeight?: number;
            fontSize: number;
        } & MediaQueriesTypes;
    }
}

// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
    interface TypographyPropsVariantOverrides {
        h5userMenu: true;
        h5: true;
        captionSessionChip: true;
        caption: true;
        moduleContentTitle: true;
        newsCardDate: true;
        body2: true;
        body3: true;
        h3: true;
        minorSubtitle: true;
        tabs: true;
    }
}

declare module "@mui/material/styles" {
    interface ThemeOptions {
        background?: {
            default?: string;
        };
        primary?: {
            main: string;
        };
        secondary?: {
            main: string;
        };
    }
}

type PurchasesConfigTypes = {
    apiKey: string;
    appUserID?: string;
};

const openEnrolment = (user: User, pathname: string, setter: Dispatch<SetStateAction<boolean>>) => {
    if (pathname === "/login" || pathname === "/login-legacy" || pathname === "/register") setter(false);
    else {
        const deviceType = process.env.NEXT_PUBLIC_VERCEL_ENV ? detectDeviceType() : "Desktop";
        /* if (deviceType === "Mobile") setter(true); */
    }
};

const pushNotificationsImpl = async () => {
    await PushNotifications.addListener("registration", (token) => {
        console.info("Registration token: ", token.value);
    });
    await PushNotifications.addListener("registrationError", (err) => {
        console.error("Registration error: ", err.error);
    });
    await PushNotifications.addListener("pushNotificationReceived", (notification) => {
        console.log("Push notification received: ", notification);
    });
    await PushNotifications.addListener("pushNotificationActionPerformed", (notification) => {
        const router = useRouter();
        const data = notification.notification.data;
        if (data.route) {
            router.push("/" + data.route);
        }
    });
    let permStatus = await PushNotifications.checkPermissions();
    if (permStatus.receive === "prompt") {
        permStatus = await PushNotifications.requestPermissions();
    }
    if (permStatus.receive !== "granted") {
        throw new Error("User denied permissions!");
    }
    await PushNotifications.register();
    await PushNotifications.getDeliveredNotifications();
};

Highcharts.dateFormats = {
    q: (timestamp: number) => String(dayjs(timestamp).quarter()),
    y: (timestamp: number) => String(dayjs(timestamp).year()),
};

if (typeof Highcharts === "object") {
    HighchartsMore(Highcharts);
    PatternFill(Highcharts);
    SolidGauge(Highcharts);
    NoDataToDisplay(Highcharts);
    /* HighchartsDebugger(Highcharts); */
    Boost(Highcharts);
}

if (typeof HighStock === "object") {
    HighchartsMore(HighStock);
    IndicatorsAll(HighStock);
    AnnotationsAdvanced(HighStock);
    PriceIndicator(HighStock);
    FullScreen(HighStock);
    StockTools(HighStock);
    Exporting(HighStock);
    OfflineExporting(HighStock);
    ExportData(HighStock);
    /* HighchartsDebugger(HighStock); */
    Boost(Highcharts);
}

axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.xsrfHeaderName = "X-CSRFToken";
axios.defaults.validateStatus = (status) => status < 500;
axios.defaults.baseURL = process.env.NEXT_PUBLIC_API_URL;
axios.defaults.withCredentials = !!process.env.NEXT_PUBLIC_VERCEL_ENV;
axios.defaults.params = { GUIv: 2, authid: process.env.NEXT_PUBLIC_VERCEL_ENV ? undefined : "ortex" };

let ortexSocketTimeout: NodeJS.Timeout;
let obookSocketTimeout: NodeJS.Timeout;
let multiSocketTimeout: NodeJS.Timeout;

let customViewsTimeout: NodeJS.Timeout;

const TEN_SECONDS = 10000;
const FOUR_HOURS = 14400000;

let pageViews = 0;

const shouldRender = (user: User, subscription: string, url: string, countPages = 5) =>
    countPages === pageViews && user?.subscription_plan === subscription && !["/login", "/login-legacy", "/cognito-login", "/register", "/iframes/", "/auth-redirect"].includes(url);

const App = ({ Component, pageProps, router: { events, pathname, query } }: AppProps) => {
    const setOpenMenu = useSetRecoilState(openMenuState);
    const [theme, setTheme] = useRecoilState(themeState);
    const userData = useRecoilValue(userDataState);
    const ortexColor = useRecoilValue(ortexColorState);
    const positive = useRecoilValue(positiveState);
    const negative = useRecoilValue(negativeState);
    const neutral = useRecoilValue(neutralState);
    const textColor = useRecoilValue(textColorState);
    const greyMedium = useRecoilValue(greyMediumState);
    const greyLighter = useRecoilValue(greyLighterState);
    const backgroundColor = useRecoilValue(backgroundColorState);
    const darkerBackgroundColor = useRecoilValue(darkerBackgroundColorState);
    const darkSurfaceDefaultColor = useRecoilValue(darkSurfaceDefaultState);
    const newbackgroundColor = useRecoilValue(newbackgroundColorState);
    const universes = useRecoilValue(universesState);
    const whiteColor = useRecoilValue(whiteState);
    const setUniverse = useSetRecoilState(universeState);
    const user = useRecoilValue(userState);
    const [customViews, setCustomViews] = useRecoilState(customViewsState);
    const [unsavedChanges, setUnsavedChanges] = useRecoilState(customViewsUnsavedState);
    const [showUnsavedChangesModal, setShowUnsavedChangesModal] = useState(false);
    const currentViewData = useRecoilValue(customViewsCurrentViewDataState);
    const [urlToGo, setUrlToGo] = useState(null);

    const [signUp, setSignUp] = useState(false);
    const subscription = useRecoilValue(subscriptionState);
    const [multiWebSocket, setMultiWebSocket] = useState<WebSocket>();
    const [obookWebSocket, setObookWebSocket] = useState<WebSocket>();
    const [ortexWebSocket, setOrtexWebSocket] = useState<WebSocket>();
    const [loading, setLoading] = useState(true);
    const [enrolment, setEnrolment] = useState<boolean>(null);
    const [offerings, setOfferings] = useRecoilState(subscriptionOfferingsState);

    const { handleAppUpgrade } = useHandleAppUpgrade();
    const { openSubscriptionForm } = useOpenSubscriptionForm();

    const setNotificationsList = useSetRecoilState(notificationsListState);
    const stockTradingItemId = useRecoilValue(stockTradingItemIdState);

    const { asPath, push, replace } = useRouter();

    const [showAppSubscriberModal, setShowAppSubscriberModal] = useRecoilState(showAppSubscriberModalState);
    const [showAgreementsModal, setShowAgreementsModal] = useRecoilState(showAgreementsModalState);

    const [promoCode, setPromoCode] = useState<string | null>(null);

    const [isAuthenticated, setIsAuthenticated] = useState(false);

    const amplifySignOut = async (vertexUser, ortexUser) => {
        try {
            await signOutFromBackend(vertexUser, ortexUser);
            await signOut();

            localStorage.removeItem("vertexUser");
            localStorage.removeItem("ortexUser");

            /*  push("/cognito-login"); */
        } catch (error) {
            console.error("Error signing out user", error);
        }
    };

    useEffect(() => {
        if (query.hasOwnProperty("promo")) {
            query.promo ? sessionStorage.setItem("promoCode", query.promo as string) : sessionStorage.setItem("promoCode", "true");

            setPromoCode(query.promo as string);
        }
    }, [query.message, query.promo]);

    Amplify.configure(awsExports);

    const saveAuthTokens = async () => {
        try {
            const { tokens } = await fetchAuthSession();

            const idToken = tokens?.idToken?.toString();
            console.log("idToken", idToken);

            if (idToken) {
                const vertexUrl = `${process.env.NEXT_PUBLIC_API_URL_VERTEX}/users/auth/cognito/`;
                const ortexUrl = `${process.env.NEXT_PUBLIC_API_URL}/users/auth/cognito/`;

                const vertexPost = axios.post(vertexUrl, idToken, {
                    withCredentials: true,
                    xsrfCookieName: "vertex_csrftoken",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    params: null,
                });

                const ortexPost = axios.post(ortexUrl, idToken, {
                    withCredentials: true,
                    headers: {
                        "Content-Type": "application/json",
                    },
                    params: null,
                });

                const [vertexResponse, ortexResponse] = await Promise.all([vertexPost, ortexPost]);

                console.log("vertexResponse", vertexResponse);
                console.log("ortexResponse", ortexResponse);

                const vertexUserId = vertexResponse?.data?.userId;
                const ortexUserId = ortexResponse?.data?.userId;

                if (vertexUserId && ortexUserId) {
                    localStorage.setItem("vertexUser", vertexUserId);
                    localStorage.setItem("ortexUser", ortexUserId);
                } else {
                    console.error("Error saving auth tokens");
                    signOut();
                }
            }
        } catch (error) {
            console.error("Error fetching auth session:", error);
            signOut();
        }
    };

    const authState = useGetUserData(isAuthenticated);

    useEffect(() => {
        if (!authState.userLoading) {
            replace("/");
        }
    }, [authState.userLoading]);

    //auth
    useEffect(() => {
        const hubListenerCancel = Hub.listen("auth", async (data) => {
            const { payload } = data;
            const vertexUser = localStorage.getItem("vertexUser");
            const ortexUser = localStorage.getItem("ortexUser");

            console.log("A new auth event has happened: ", JSON.stringify(data.payload) + " has " + data.payload.event);

            switch (payload.event) {
                case "signedIn":
                    saveAuthTokens()
                        .then(() => {
                            setIsAuthenticated(true);
                        })
                        .catch((error) => {
                            console.error("Error saving auth tokens:", error);
                        });
                    break;
                case "signedOut":
                    console.log("user signed out");
                    break;
                case "customOAuthState":
                    console.log("customOAuthState");
                    break;
                case "tokenRefresh_failure":
                    console.log("tokenRefresh_failure");
                    break;
                case "tokenRefresh":
                    console.log("tokenRefresh");
                    break;
                case "signInWithRedirect":
                    console.log("signInWithRedirect");
                    break;
                case "signInWithRedirect_failure":
                    amplifySignOut(vertexUser, ortexUser);

                    toast("You've been successfully migrated to a new database, please try to log in again", { type: "error" });

                    break;
                default:
                    console.log("default");
                    break;
            }
        });

        return () => {
            console.log("hub listener cancel");
            hubListenerCancel();
        };
    }, []);

    //notifications
    const extractPath = useCallback(() => {
        if (asPath.includes("/s/")) {
            return asPath.split("/")[4];
        } else {
            return asPath.split("/")[1].split("?")[0];
        }
    }, [asPath]);

    const extractStock = useCallback(() => {
        if (asPath.includes("/s/")) {
            return stockTradingItemId;
        } else {
            return null;
        }
    }, [asPath, stockTradingItemId]);

    const checkNotifications = async () => {
        const page = asPath ? extractPath() : null;
        const stock = asPath ? extractStock() : null;

        getNotifications(page, stock).then((res) => {
            setNotificationsList(res.results);
        });
    };

    useDebounce(
        () => {
            checkNotifications();
        },
        500,
        [asPath, stockTradingItemId]
    );

    //subscription config

    const public_apple_api_key = "appl_DNEBYogsWNyxldysidPVItHMkiT";
    const public_google_api_key = "goog_WAcvFMuzarNalZjeqWQBDBFPvny";

    const paymentConfig = useCallback(async () => {
        if (Capacitor.getPlatform() === "ios") {
            const config: PurchasesConfigTypes = { apiKey: public_apple_api_key };

            if (user?.user_id) {
                config.appUserID = user.user_id.toString();
            }

            console.log("ios config ", JSON.stringify(config));

            await Purchases.configure(config);
        } else if (Capacitor.getPlatform() === "android") {
            const config: PurchasesConfigTypes = { apiKey: public_google_api_key };

            if (user?.user_id) {
                config.appUserID = user.user_id.toString();
            }

            console.log("android config ", JSON.stringify(config));

            await Purchases.configure(config);
        }
    }, [user]);

    const fetchOfferings = async () => {
        const offeringsResponse = await Purchases.getOfferings();
        if (offeringsResponse.current !== null && offeringsResponse.current.availablePackages.length !== 0) {
            console.log("Offerings: ", offeringsResponse.current);
            setOfferings(offeringsResponse.current.availablePackages[0]);
        }
    };

    useEffect(() => {
        if (!user.subscription_plan) return;
        const onDeviceReady = () => {
            console.log("Device ready!");
            !offerings &&
                paymentConfig().then(() => {
                    fetchOfferings().catch((e) => console.log(JSON.stringify(e)));
                });
        };

        document.addEventListener("deviceready", onDeviceReady, false);

        return () => {
            document.removeEventListener("deviceready", onDeviceReady, false);
        };
    }, [paymentConfig, user]);

    const defaultTheme = createTheme({
        typography: {
            moduleContentTitle: {
                fontWeight: 700,
                fontSize: 12,
                "@media screen and (min-width: 768px)": {
                    fontSize: 12,
                },
                "@media screen and (min-width: 1280px)": {
                    fontSize: 12,
                },
                "@media screen and (min-width: 1920px)": {
                    fontSize: 16,
                },
                "@media screen and (min-width: 3840px)": {
                    fontSize: 22,
                },
            },
            body1: {
                fontSize: 12,
                "@media screen and (min-width: 768px)": {
                    fontSize: 12,
                },
                "@media screen and (min-width: 1280px)": {
                    fontSize: 12,
                },
                "@media screen and (min-width: 1440px)": {
                    fontSize: 14,
                },
                "@media screen and (min-width: 1920px)": {
                    fontSize: 16,
                },
                "@media screen and (min-width: 3840px)": {
                    fontSize: 22,
                },
            },
            body2: {
                fontSize: 10,
                "@media screen and (min-width: 768px)": {
                    fontSize: 10,
                },
                "@media screen and (min-width: 1280px)": {
                    fontSize: 10,
                },
                "@media screen and (min-width: 1440px)": {
                    fontSize: 12,
                },
                "@media screen and (min-width: 1920px)": {
                    fontSize: 14,
                },
                "@media screen and (min-width: 3840px)": {
                    fontSize: 20,
                },
            },
            body3: {
                fontSize: 10,
                "@media screen and (min-width: 768px)": {
                    fontSize: 10,
                },
                "@media screen and (min-width: 1280px)": {
                    fontSize: 10,
                },
                "@media screen and (min-width: 1440px)": {
                    fontSize: 11,
                },
                "@media screen and (min-width: 1920px)": {
                    fontSize: 12,
                },
                "@media screen and (min-width: 3840px)": {
                    fontSize: 18,
                },
            },
            h1: {
                fontWeight: 500,
                fontSize: 18,
                "@media screen and (min-width: 768px)": {
                    fontSize: 18,
                },
                "@media screen and (min-width: 1280px)": {
                    fontSize: 18,
                },
                "@media screen and (min-width: 1920px)": {
                    fontSize: 20,
                },
                "@media screen and (min-width: 3840px)": {
                    fontSize: 26,
                },
            },
            h2: {
                fontWeight: 500,
                fontSize: 16,
                "@media screen and (min-width: 768px)": {
                    fontSize: 16,
                },
                "@media screen and (min-width: 1280px)": {
                    fontSize: 16,
                },
                "@media screen and (min-width: 1920px)": {
                    fontSize: 18,
                },
                "@media screen and (min-width: 3840px)": {
                    fontSize: 26,
                },
            },
            h3: {
                fontSize: 12,
                "@media screen and (min-width: 768px)": {
                    fontSize: 12,
                },
                "@media screen and (min-width: 1280px)": {
                    fontSize: 12,
                },
                "@media screen and (min-width: 1920px)": {
                    fontSize: 14,
                },
                "@media screen and (min-width: 3840px)": {
                    fontSize: 20,
                },
            },

            subtitle2: {
                fontSize: 10,
                "@media screen and (min-width: 768px)": { fontSize: 10 },
                "@media screen and (min-width: 1280px)": { fontSize: 10 },
                "@media screen and (min-width: 1920px)": { fontSize: 12 },
                "@media screen and (min-width: 3840px)": { fontSize: 16 },
            },
            overline: {
                fontWeight: 100,
                fontSize: 10,
                "@media screen and (min-width: 768px)": { fontSize: 10 },
                "@media screen and (min-width: 1280px)": { fontSize: 10 },
                "@media screen and (min-width: 1920px)": { fontSize: 12 },
                "@media screen and (min-width: 3840px)": { fontSize: 18 },
            },
            h5userMenu: {
                fontWeight: 100,
                fontSize: 11,
                "@media screen and (min-width: 768px)": { fontSize: 12 },
                "@media screen and (min-width: 1280px)": { fontSize: 12 },
                "@media screen and (min-width: 1920px)": { fontSize: 14 },
                "@media screen and (min-width: 3840px)": { fontSize: 18 },
            },
            captionSessionChip: {
                fontSize: 12,
                "@media screen and (min-width: 768px)": { fontSize: 12 },
                "@media screen and (min-width: 1280px)": { fontSize: 12 },
                "@media screen and (min-width: 1920px)": { fontSize: 12 },
                "@media screen and (min-width: 3840px)": { fontSize: 16 },
            },
            newsCardDate: {
                fontWeight: 600,
                fontSize: 12,
                "@media screen and (min-width: 768px)": { fontSize: 12 },
                "@media screen and (min-width: 1280px)": { fontSize: 12 },
                "@media screen and (min-width: 1920px)": { fontSize: 14 },
                "@media screen and (min-width: 3840px)": { fontSize: 20 },
            },
            minorSubtitle: {
                fontSize: 8,
                "@media screen and (min-width: 768px)": { fontSize: 8 },
                "@media screen and (min-width: 1280px)": { fontSize: 9 },
                "@media screen and (min-width: 1920px)": { fontSize: 10 },
                "@media screen and (min-width: 3840px)": { fontSize: 14 },
            },
            tabs: {
                fontWeight: 700,
                fontSize: 8,
                "@media screen and (min-width: 768px)": { fontSize: 9 },
                "@media screen and (min-width: 1280px)": { fontSize: 9 },
                "@media screen and (min-width: 1920px)": { fontSize: 10 },
                "@media screen and (min-width: 3840px)": { fontSize: 14 },
            },
        },
        components: {
            MuiTypography: {
                defaultProps: {
                    variantMapping: {
                        h5userMenu: "h5",
                        captionSessionChip: "caption",
                        newsCardDate: "body2",
                        minorSubtitle: "subtitle2",
                    },
                },
            },
        },
    });

    const light = createTheme({
        ...(defaultTheme as ThemeOptions),
        components: {
            MuiPaper: {
                styleOverrides: {
                    root: {
                        backgroundColor: newbackgroundColor,
                    },
                },
            },
            MuiInputBase: {
                styleOverrides: {
                    root: {
                        color: textColor,
                        backgroundColor: backgroundColor,
                    },
                },
            },
            MuiSelect: {
                styleOverrides: {
                    icon: {
                        color: "#000",
                    },
                },
            },
        },
        palette: {
            background: {
                default: newbackgroundColor,
            },
            primary: {
                main: ortexColor,
            },
            info: {
                main: "#3b97ef",
            },
            text: {
                primary: textColor,
            },
            success: {
                main: positive,
            },
            error: {
                main: negative,
            },
        },
    });

    const dark = createTheme({
        ...(defaultTheme as ThemeOptions),
        components: {
            MuiPaper: {
                styleOverrides: {
                    root: {
                        backgroundColor: darkSurfaceDefaultColor,
                    },
                },
            },
            MuiCheckbox: {
                styleOverrides: {
                    root: {
                        color: textColor,
                    },
                },
            },
            MuiToggleButton: {
                styleOverrides: {
                    root: {
                        color: new Color(whiteColor).brighten(-0.1).get() as string,
                    },
                },
            },
            MuiInput: {
                styleOverrides: {
                    underline: {
                        "&&::before": {
                            borderColor: "rgb(128, 128, 128)", // greyState
                        },
                        "&&:hover::before": {
                            borderColor: greyLighter,
                        },
                    },
                },
            },
            MuiInputBase: {
                styleOverrides: {
                    root: {
                        color: textColor,
                        backgroundColor: backgroundColor,

                        "& .MuiOutlinedInput-notchedOutline": {
                            border: "1px solid rgb(128, 128, 128)",
                        },
                    },
                },
            },
            MuiSelect: {
                styleOverrides: {
                    icon: {
                        color: "#e6e6e6",
                    },
                },
            },
        },
        palette: {
            background: {
                default: darkerBackgroundColor,
            },
            primary: {
                dark: "#0f524a",
                main: "rgb(49, 171, 166)",
                light: "#e1f3f3",
            },
            secondary: {
                dark: "#0f524a",
                main: ortexColor,
                light: "#e1f3f3",
            },
            info: {
                main: "#3b97ef",
            },
            text: {
                disabled: "rgb(128, 128, 128)", // greyState
                primary: textColor,
                secondary: "rgb(128, 128, 128)", // greyState
            },
        },
    });

    useEffect(() => {
        GoogleAuth.initialize();
    }, []);

    const signUpOpened = useRef(false);

    const userIdle = useIdle(FOUR_HOURS);

    let Layout = RootLayout;

    if (["/login", "/login-legacy", "/cognito-login", "/iframes", "/register", "/auth-redirect"].includes(pathname)) Layout = AuthLayout;

    useEffect(() => {
        axios.interceptors.response.use(
            (res) => res,
            (err) => {
                const { config, message } = err.toJSON();
                if (message !== "canceled" && config.url !== "/logout")
                    toast(
                        `Service error. Please try again later.${
                            process.env.NEXT_PUBLIC_VERCEL_ENV
                                ? ""
                                : `
                        ${message} - ${config.method.toUpperCase()} at:
                        ${config.url}`
                        }`,
                        { type: "error" }
                    );
                throw err;
            }
        );

        const isPushNotificationsAvailable = Capacitor.isPluginAvailable("PushNotifications");

        if (isPushNotificationsAvailable) {
            PushNotifications.addListener("registration", (token) => console.info("Registration token: ", token.value));

            PushNotifications.addListener("registrationError", (err) => console.error("Registration error: ", err.error));

            PushNotifications.addListener("pushNotificationReceived", (notification) => console.log("Push notification received: ", notification));

            PushNotifications.addListener("pushNotificationActionPerformed", (notification) => {
                const data = notification.notification.data;
                if (data.route) push("/" + data.route);
            });

            PushNotifications.checkPermissions().then((permChecked) => {
                if (permChecked.receive === "prompt") {
                    PushNotifications.requestPermissions().then((permRequested) => {
                        if (permRequested.receive !== "granted") throw new Error("User denied permissions!");

                        PushNotifications.register();
                        PushNotifications.getDeliveredNotifications();
                    });
                }
            });
        }

        CapacitorApp.addListener("backButton", ({ canGoBack }) => {
            if (!canGoBack) {
                CapacitorApp.exitApp();
            } else {
                window.history.back();
            }
        });
    }, []);

    const sendHelloMulti = useCallback(() => multiWebSocket.send(JSON.stringify({ type: "hello" })), [multiWebSocket]);
    const sendHelloOrtex = useCallback(() => ortexWebSocket.send(JSON.stringify({ type: "hello" })), [ortexWebSocket]);
    const sendHelloObook = useCallback(() => obookWebSocket.send(JSON.stringify({ type: "hello" })), [obookWebSocket]);

    const closeMultiSocket = useCallback(() => multiWebSocket.close(), [multiWebSocket]);
    const closeOrtexSocket = useCallback(() => ortexWebSocket.close(), [ortexWebSocket]);
    const closeObookSocket = useCallback(() => obookWebSocket.close(), [obookWebSocket]);

    const resetMultiSocket = useCallback(() => {
        clearTimeout(multiSocketTimeout);
        multiSocketTimeout = setTimeout(() => setMultiWebSocket(new WebSocket(`${process.env.NEXT_PUBLIC_MULTI_WS_URL}/stock_price/MULTI`)), TEN_SECONDS);
    }, []);

    const resetOrtexSocket = useCallback(() => {
        clearTimeout(ortexSocketTimeout);
        ortexSocketTimeout = setTimeout(() => setOrtexWebSocket(new WebSocket(`${process.env.NEXT_PUBLIC_ORTEX_WS_URL}/stock_price`)), TEN_SECONDS);
    }, []);

    const resetObookSocket = useCallback(() => {
        clearTimeout(obookSocketTimeout);
        obookSocketTimeout = setTimeout(() => setObookWebSocket(new WebSocket(`${process.env.NEXT_PUBLIC_OBOOK_WS_URL}/stock_price`)), TEN_SECONDS);
    }, []);

    useEffect(() => {
        const savedTheme = localStorage.getItem("theme");
        const savedOpenMenu = localStorage.getItem("open-menu");
        setTheme(["dark", "light"].includes(savedTheme) ? (savedTheme as "dark" | "light") : "dark");
        setOpenMenu(["0", "1"].includes(savedOpenMenu) ? (Number(savedOpenMenu) as 0 | 1) : 1);
    }, []);

    const onRouteChangeStart = useCallback(
        (url) => {
            if (pathname.includes("/custom-views") && unsavedChanges) {
                setUrlToGo(url);
                setShowUnsavedChangesModal(true);
                throw new Error("Unsaved changes");
            } else {
                setLoading(true);
            }
        },
        [unsavedChanges]
    );

    useEffect(() => {
        events.on("routeChangeStart", onRouteChangeStart);

        return () => {
            events.off("routeChangeStart", onRouteChangeStart);
        };
    }, [onRouteChangeStart]);

    useEffect(() => {
        const cookie = document.cookie.split("subscriber=")[1]?.split(";")[0];

        const timeout = setTimeout(() => {
            if (shouldRender(user, "ORTEX Visitor", pathname, 0) && cookie !== "1") {
                if (pathname === "/") return;
                if (pathname.includes("/iframes/")) return;
                if (pathname.includes("/register")) return;
                if (pathname.includes("/login")) return;
                setSignUp(true);
                signUpOpened.current = true;
            }
        }, TEN_SECONDS);

        const routeChangeStart = () => setLoading(true);
        const routeChangeError = () => setLoading(false);
        const routeChangeComplete = (url: string) => {
            if (signUpOpened.current) {
                pageViews++;
                if (cookie !== "1" && !pathname.includes("/iframes/") && pathname !== "/") setSignUp(shouldRender(user, "ORTEX Visitor", url));
                openSubscriptionForm(shouldRender(user, "ORTEX Free", url));
                if (pageViews >= 5) pageViews = 0;
            }
            setLoading(false);
            window.Appcues?.page();
        };

        events.on("routeChangeError", routeChangeError);
        events.on("routeChangeComplete", routeChangeComplete);

        setShowAgreementsModal((user.ortex_tandc && user.ortex_tandc !== "agreed") || user?.subscription_plan?.includes("Visitor"));

        return () => {
            clearTimeout(timeout);
            events.off("routeChangeStart", routeChangeStart);
            events.off("routeChangeError", routeChangeError);
            events.off("routeChangeComplete", routeChangeComplete);
        };
    }, [user]);

    useEffect(() => {
        if (pathname.includes("/custom-views")) {
            const beforeUnload = (e: BeforeUnloadEvent) => {
                if (unsavedChanges) {
                    e.preventDefault();
                    e.returnValue = "";
                }
            };

            window.addEventListener("beforeunload", beforeUnload);

            return () => {
                window.removeEventListener("beforeunload", beforeUnload);
            };
        }
    }, [unsavedChanges]);

    const handleAgree = () => {
        setImmediate(() => {
            setUnsavedChanges(false);
            urlToGo && push(urlToGo);
        });
        setShowUnsavedChangesModal(false);
        setCustomViews(currentViewData);
        setUrlToGo(null);
    };

    const handleContinueWithoutSaving = () => {
        setImmediate(() => {
            urlToGo && push(urlToGo);
        });
        setUnsavedChanges(false);
        setShowUnsavedChangesModal(false);
        setUrlToGo(null);
    };

    const [flattenedUniverses, setFlattenedUniverses] = useState<Universe[]>([]);

    useEffect(() => {
        if (universes && universes.selected !== undefined) {
            const flattened = Object.values(universes?.regions)
                .reduce((acc, val) => acc.concat(val), [])
                .concat(universes.portfolios);

            setFlattenedUniverses(flattened);
        }
    }, [universes]);

    useEffect(() => {
        if (query.uni_id && flattenedUniverses.length) setUniverse(flattenedUniverses.find((universe) => universe.id === Number(query.uni_id)));
    }, [query, universes]);

    const { userLoading } = useGetUserData();

    useEffect(() => {
        setLoading(userLoading);
    }, [userLoading]);

    useEffect(() => {
        if (multiWebSocket) {
            multiWebSocket?.readyState === 1 ? sendHelloMulti() : multiWebSocket.addEventListener("open", sendHelloMulti);
            multiWebSocket.addEventListener("error", closeMultiSocket);
            multiWebSocket.addEventListener("close", resetMultiSocket);
        }
        return () => {
            if (multiWebSocket) {
                multiWebSocket.removeEventListener("open", sendHelloMulti);
                multiWebSocket.removeEventListener("error", closeMultiSocket);
                multiWebSocket.removeEventListener("close", resetMultiSocket);
            }
        };
    }, [multiWebSocket]);

    useEffect(() => {
        if (ortexWebSocket) {
            ortexWebSocket.readyState === 1 ? sendHelloOrtex() : ortexWebSocket.addEventListener("open", sendHelloOrtex);
            ortexWebSocket.addEventListener("error", closeOrtexSocket);
            ortexWebSocket.addEventListener("close", resetOrtexSocket);
        }
        return () => {
            if (ortexWebSocket) {
                ortexWebSocket.removeEventListener("open", sendHelloOrtex);
                ortexWebSocket.removeEventListener("error", closeOrtexSocket);
                ortexWebSocket.removeEventListener("close", resetOrtexSocket);
            }
        };
    }, [ortexWebSocket]);

    useEffect(() => {
        if (obookWebSocket) {
            obookWebSocket.readyState === 1 ? sendHelloObook() : obookWebSocket.addEventListener("open", sendHelloObook);
            obookWebSocket.addEventListener("error", closeObookSocket);
            obookWebSocket.addEventListener("close", resetObookSocket);
        }
        return () => {
            if (obookWebSocket) {
                obookWebSocket.removeEventListener("open", sendHelloObook);
                obookWebSocket.removeEventListener("error", closeObookSocket);
                obookWebSocket.removeEventListener("close", resetObookSocket);
            }
        };
    }, [obookWebSocket]);

    useEffect(() => {
        if (!userIdle) {
            setMultiWebSocket(new WebSocket(`${process.env.NEXT_PUBLIC_MULTI_WS_URL}/stock_price/MULTI`));
            setOrtexWebSocket(new WebSocket(`${process.env.NEXT_PUBLIC_ORTEX_WS_URL}/stock_price`));
            setObookWebSocket(new WebSocket(`${process.env.NEXT_PUBLIC_OBOOK_WS_URL}/stock_price`));
        }
    }, [userIdle]);

    useEffect(() => {
        if (userIdle) {
            if (multiWebSocket?.readyState === 1) {
                multiWebSocket.removeEventListener("close", resetMultiSocket);
                closeMultiSocket();
            }
            if (ortexWebSocket?.readyState === 1) {
                ortexWebSocket.removeEventListener("close", resetOrtexSocket);
                closeOrtexSocket();
            }
            if (obookWebSocket?.readyState === 1) {
                obookWebSocket.removeEventListener("close", resetObookSocket);
                closeObookSocket();
            }
            push("/idle");
        }
    }, [userIdle, multiWebSocket, obookWebSocket, ortexWebSocket]);

    useEffect(() => openEnrolment(user, pathname, setEnrolment), [pathname, user]);

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme === "dark" ? dark : light}>
                <CssBaseline />
                <Context.Provider value={{ multiWebSocket, obookWebSocket, ortexWebSocket }}>
                    <style jsx global>{`
                        ::-webkit-scrollbar-thumb {
                            border: 4px solid ${isUndefined(theme) ? "unset" : backgroundColor};
                            background-color: ${isUndefined(theme) ? "unset" : theme === "dark" ? greyMedium : greyLighter};
                        }

                        html {
                            color: ${isUndefined(theme) ? "unset" : textColor};
                            scrollbar-color: ${isUndefined(theme) ? "unset" : theme === "dark" ? greyMedium : greyLighter} transparent;
                        }
                        .positive {
                            color: ${positive};
                        }
                        .bg-positive {
                            background-color: ${positive};
                        }
                        .border-positive {
                            border: solid;
                            border-color: ${positive};
                        }
                        .negative {
                            color: ${negative};
                        }
                        .bg-negative {
                            background-color: ${negative};
                        }
                        .bc-negative {
                            border-color: ${negative};
                        }
                        .bc-positive {
                            border-color: ${positive};
                        }
                        .border-negative {
                            border: solid;
                            border-color: ${negative};
                        }
                        .neutral {
                            color: ${neutral};
                        }
                        .bg-neutral {
                            background-color: ${neutral};
                        }
                        .border-neutral {
                            border: solid;
                            border-color: ${neutral};
                        }
                        form iframe {
                            margin: 0 !important;
                            width: 100% !important;
                        }
                        .MuiBackdrop-root {
                            background-color: rgba(0, 0, 0, 0.5);
                        }
                        .MuiDataGrid-paper {
                            background-color: ${theme === "dark" ? "rgb(48 67 77)" : backgroundColor};
                        }

                        .MuiDataGrid-menu .MuiPaper-root {
                            background-color: ${theme === "dark" ? "rgb(48 67 77)" : backgroundColor};
                        }

                        .MuiDateRangePickerViewDesktop-root {
                            background-color: ${theme === "dark" ? "rgb(48 67 77)" : backgroundColor};
                        }

                        .MuiDateRangePickerViewDesktop-root .MuiSvgIcon-root {
                            color: ${theme === "dark" ? "#FFF" : "#000"};
                        }
                        .MuiDateRangePickerViewDesktop-root .defaultCalendarMonth {
                            color: "green";
                        }

                        .MuiToggleButtonGroup-grouped {
                            border-color: ${theme === "dark" ? "rgba(230,230,230, 0.5)" : null};
                        }

                        .MuiDialog-paper {
                            @media screen and (min-width: 3840px) {
                                max-width: 1400px;
                            }
                        }
                    `}</style>
                    <Script src="https://www.googletagmanager.com/gtag/js?id=AW-692704651" strategy="afterInteractive" />
                    <Script id="google-analytics" strategy="afterInteractive">
                        {`
                        window.dataLayer = window.dataLayer || [];
                        function gtag(){window.dataLayer.push(arguments);}
                        gtag('js', new Date());
                        gtag('config', 'AW-692704651');
                    `}
                    </Script>
                    <Script src="/assets/charting_library-master/charting_library.js" />
                    {/* <AppUrlListener /> */}
                    <ToastContainer closeButton={false} draggable={false} hideProgressBar={true} pauseOnFocusLoss={false} position="top-center" />
                    <ReactTooltip type={theme === "dark" ? "light" : "dark"} className="ReactTooltip" />
                    {subscription && !["/login", "/login-legacy", "/settings", "/cancel-subscription"].includes(pathname) && <SubscriptionPlans data={subscription} />}
                    <Layout {...pageProps}>
                        {(isUndefined(userData) || loading) && <BrandLoading {...pageProps} pathname={pathname} />}
                        {(user.email !== "visitor1@ortex.com" || ["/login", "/login-legacy", "/cognito-login", "/register", "/iframes", "/auth-redirect"].includes(pathname)) && <Component {...pageProps} />}
                    </Layout>
                    {!!subscription?.plans.length && !user.app_subscriber_only && (isOrtexApp() ? handleAppUpgrade() : <Payment hidden={!!(promoCode?.toUpperCase() === "FEMALEINVEST")} />)}
                    <Offcanvas isOpen={signUp} onClose={setSignUp}>
                        <SignUp />
                    </Offcanvas>
                    <CustomViewsUnsavedChangesModal show={showUnsavedChangesModal} setShow={setShowUnsavedChangesModal} agree={handleAgree} continueWithoutSaving={handleContinueWithoutSaving} />
                    {/* {enrolment && !detectGoogleBot() && <MobileDrawer />} */}
                    {showAppSubscriberModal && subscription && <AppSubscriberModal open={showAppSubscriberModal} setOpen={setShowAppSubscriberModal} />}

                    {!detectBot() && user && showAgreementsModal && !["/login", "/login-legacy", "/cognito-login", "/register", "/iframes", "/auth-redirect"].includes(pathname) && (
                        <AgreementsModal open={showAgreementsModal} setOpen={setShowAgreementsModal} visitor={user?.subscription_plan?.includes("Visitor")} user={user} />
                    )}
                </Context.Provider>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

export default withApp(App);
